import { Environment } from './environment.interface';
import versionInfo from '../../../version.json';

export const environment: Environment = {
  production: true,

  version: versionInfo.version,

  serverUrl: 'https://api.joinohana.io',
  loginUrl: 'https://login.joinohana.io',

  logging: {
    enabled: true,

    dsn: 'https://4572ff1b91fa375901368f5ec31b109c@o1412514.ingest.sentry.io/4505924119429120',
  },

  tracking: {
    enabled: true,
  },

  links: {
    termsUrl: 'https://www.joinohana.io/terms',
    privacyUrl: 'https://www.joinohana.io/privacy',
    websiteUrl: 'https://www.joinohana.io',
    blogUrl: 'https://blog.joinohana.io',
    faqUrl: 'https://www.joinohana.io/faq',
    supportEmail: 'support@joinohana.io',
  },
};
